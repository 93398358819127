import React from 'react';

import { Switch, Route, BrowserRouter } from 'react-router-dom';

import Checkout from './pages/Checkout';
import Link from './pages/Link';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/checkout/:company_id/:id" component={Checkout}/>
        <Route exact path="/link/:id" component={Link}/>
      </Switch>
    </BrowserRouter>
  )
}