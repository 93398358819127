function validarCPF(strCPF) {
  let soma;
  let resto;
  strCPF = strCPF.replace(/[^\d]+/g, "");
  soma = 0;
  if (strCPF === "00000000000") return false;

  for (
    let i = 1;
    i <= 9;
    i++ // eslint-disable-line
  )
    soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i); // eslint-disable-line
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10))) return false; // eslint-disable-line

  soma = 0;
  for (
    let i = 1;
    i <= 10;
    i++ // eslint-disable-line
  )
    soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i); // eslint-disable-line
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(10, 11))) return false; // eslint-disable-line
  return true;
}

function emailIsValid(email) {
  if (email.indexOf("@") === -1) {
    return false;
  }
  if (email.split("@")[1].indexOf(".") === -1) {
    return false;
  }
  return true;
}

export default function validation(values) {
  let lock_error = {
    name: "",
    cpf: "",
    email: "",
    phone: "",
    card_number: "",
    card_name_printed: "",
    installments: "",
    card_expire_month: "",
    card_expire_year: "",
    card_cvv: "",
    amount: "",
  };

  const keys = Object.keys(lock_error);

  function switchValidate(key, value) {
    switch (key) {
      case "cpf": {
        if (!value) {
          return "Preencha esse campo";
        }
        if (!validarCPF(value)) {
          return "CPF inválido";
        }
        return "";
      }
      case "email": {
        if (!value) {
          return "Preencha esse campo";
        }
        if (!emailIsValid(value)) {
          return "E-mail não é válido";
        }
        return "";
      }
      case "card_number": {
        if (!value) {
          return "Preencha esse campo";
        }
        if (value.length < 19) {
          return "Preencha esse campo";
        }
        return "";
      }
      case "phone": {
        if (!value) {
          return "Preencha esse campo";
        }
        if (value.length < 14) {
          return "Preencha esse campo";
        }
        return "";
      }
      case "complement": {
        return "";
      }
      default: {
        if (!value) {
          return "Preencha esse campo";
        }
        if (value.length === 0) {
          return "Preencha esse campo";
        }
        return "";
      }
    }
  }
  for (let i = 0; i < keys.length; i += 1) {
    lock_error[keys[i]] = switchValidate(keys[i], values[keys[i]]);
  }

  return lock_error;
}
