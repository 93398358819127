import React, { useState, useEffect, useRef, useMemo } from "react";

import axios from "axios";

import dataAnimation from "./utils/success_animation.json";
import Lottie from "react-lottie";

import ReCAPTCHA from "react-google-recaptcha";

import { Spinner, Form, Alert } from "react-bootstrap";
import { AiFillApi } from "react-icons/ai";
import {
  FaUser,
  FaCreditCard,
  FaCartPlus,
  FaPlusCircle,
  FaMinusCircle,
  FaTrash,
  FaInfoCircle,
} from "react-icons/fa";
import { MdPersonPinCircle } from 'react-icons/md';

import {
  normalizeCurrency,
  normalizeCpf,
  normalizeCardNumber,
  normalizeCvv,
  normalizePhone,
  normalizeCnpj,
} from "./utils/normalize";

import validation from "./utils/validation";

import "./styles.scss";
import { ModalProduct } from "../../components/ModalProduct";

function Checkout({ match }) {
  const target = new URLSearchParams(window.location.search).get('target')

  const { id, company_id } = match.params;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [colors] = useState({
    primary: "#f2dec1",
    secondary: "#191919",
  });
  const [productDetail, setProductDetail] = useState({})
  const [others, setOthers] = useState([]);
  const [productsAdded, setProductsAdded] = useState([]);
  const captcha = useRef(null);
  const [companyLogo, setCompanyLogo] = useState("https://pag.tupi.io/pele/assets/pele_logo_new.png");
  const [scripts, setScripts] = useState([]);

  const actualYear = new Date().getFullYear();
  const renderYears = useMemo(() => {
    let years = [];
    for (let i = actualYear; i < actualYear + 15; i += 1) {
      years.push(i);
    }
    return (
      <>
        {years.map((i) => (
          <option value={String(i)} key={i}>
            {i}
          </option>
        ))}
      </>
    );
  }, [actualYear]);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: dataAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [values, setValues] = useState({
    name: "",
    cpf: "",
    email: "",
    phone: "",
    card_number: "",
    card_name_printed: "",
    installments: "",
    card_expire_month: "",
    card_expire_year: "",
    cvv: "",
    amount: 1,
  });
  const [errors, setErrors] = useState({
    name: "",
    cpf: "",
    email: "",
    phone: "",
    card_number: "",
    card_name_printed: "",
    installments: "",
    card_expire_month: "",
    card_expire_year: "",
    card_cvv: "",
    amount: "",
  });

  const [errorProduct, setErrorProduct] = useState("");

  const [sellerDigit, setSellerDigit] = useState("");
  const [sellerApplied, setSellerApplied] = useState({});
  const [errorSeller, setErrorSeller] = useState("");

  async function handleApplySeller() {
    setErrorSeller("");
    setLoading(true);
    try {
      const { data: dataSeller } = await axios.post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:3333"
            : "https://checkout.api.tupi.io"
        }/seller-is-valid`,
        {
          company_id: data.company_id,
          code: sellerDigit.toUpperCase(),
        }
      );
      setSellerApplied(dataSeller);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setErrorSeller(err.response.data.message);
      } else {
        setErrorSeller(err.message);
      }
    }
    setLoading(false);
  }

  const [msgTransaction, setMsgTransacion] = useState({
    text: "",
    type: "",
  });

  useEffect(() => {
    async function loadProduct() {
      setLoading(true);
      try {
        const { data: rData } = await axios.get(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:3333"
              : "https://checkout.api.tupi.io"
          }/products/${id}`
        );
        if (rData.product.active === 0) {
          setErrorProduct("Que pena... Este produto não está disponível.");
        } else {
          setData(rData.product);
          setOthers(rData.others);
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setErrorProduct("Nenhum produto encontrado...");
        } else if (err.response && err.response.status === 500) {
          setErrorProduct(
            "Ocorreu um erro em nossos servidores. Tente novamente mais tarde."
          );
        } else {
          setErrorProduct("Ocorreu um erro, atualize a página.");
        }
      }
      try {
        const { data: dataColor } = await axios.get(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:3333"
              : "https://checkout.api.tupi.io"
          }/company/${company_id}`
        );
        // setColors({
        //   primary: dataColor.color_primary,
        //   secondary: dataColor.color_secondary,
        // });
        if (dataColor.logo_image) {
          setCompanyLogo(
            `${
              process.env.NODE_ENV === "development"
                ? "http://localhost:3333"
                : "https://checkout.api.tupi.io"
            }/files/${dataColor.logo_image}`
          );
        }
        dataColor.scripts.forEach((script) => {
          const scriptEl = document.createElement("script");
          scriptEl.src = `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:3333"
              : "https://checkout.api.tupi.io"
          }/files/${script.file}`;
          document.body.appendChild(scriptEl);
        });
        setScripts(dataColor.scripts);
        if (dataColor.scripts.length > 0) {
          const hiddenValue = document.createElement("input");
          hiddenValue.setAttribute("type", "hidden");
          hiddenValue.setAttribute("id", "ProductValueHidden");
          const hiddenId = document.createElement("input");
          hiddenId.setAttribute("type", "hidden");
          hiddenId.setAttribute("id", "IdProductHidden");
          document.body.appendChild(hiddenId);
          document.body.appendChild(hiddenValue);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    }
    loadProduct();
  }, [id, company_id]);

  const total = useMemo(() => {
    let t = 0;
    t += data.value * values.amount;
    productsAdded.forEach((p) => {
      t += p.product.value * p.quantity;
    });
    return t;
  }, [productsAdded, values.amount, data.value]);

  const renderInstallments = useMemo(() => {
    const i = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    return (
      <>
        {i.map((c) => {
          if (Math.floor(total / c) >= 1) {
            return (
              <option value={`${c}`}>
                {c}x de R$ {normalizeCurrency(Math.floor(total / c))}
              </option>
            );
          }
          return "";
        })}
      </>
    );
  }, [total]);

  async function handleSubmit(e) {
    e.preventDefault();
    setErrors({
      name: "",
      cpf: "",
      email: "",
      phone: "",
      card_number: "",
      card_name_printed: "",
      installments: "",
      card_expire_month: "",
      card_expire_year: "",
      card_cvv: "",
      amount: "",
    });

    let lock_error = validation(values);

    const keys = Object.keys(lock_error);

    for (let i = 0; i < keys.length; i += 1) {
      if (lock_error[keys[i]] !== "") {
        setErrors(lock_error);
        break;
      }
    }

    setLoading(true);
    const token = await captcha.current.executeAsync();
    captcha.current.reset();

    if (!token) {
      setMsgTransacion({
        type: "danger",
        msg: 'Desafio de "Não sou um robô" não realizado',
      });
      setLoading(false);
      return false;
    }
    try {
      const products = [];
      products.push({
        id,
        quantity: values.amount,
      });
      productsAdded.forEach((p) => {
        products.push({
          id: p.product.id,
          quantity: p.quantity,
        });
      });
      const { data } = await axios.post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:3333"
            : "https://checkout.api.tupi.io"
        }/orders/${id}`,
        {
          ...values,
          products,
          card_number: values.card_number.replace(/\s/g, ""),
          installments: Number(values.installments),
          gResponse: token,
          seller_id: sellerApplied ? sellerApplied.id : undefined,
        }
      );
      if (scripts.length > 0) {
        const valueTotal = String(data.value * values.amount);
        document.getElementById(
          "ProductValueHidden"
        ).value = `${valueTotal.slice(
          0,
          valueTotal.length - 2
        )}.${valueTotal.slice(valueTotal.length - 2, valueTotal.length)}`;
        document.getElementById("IdProductHidden").value = data.id;
        scripts.forEach((script) => {
          if (script.type && script.type.length > 0) {
            const scriptEl = document.createElement("script");
            scriptEl.src = `/scripts/${script.type}.js`;
            document.body.appendChild(scriptEl);
          }
        });
      }
      setTimeout(() => {
        try {
          document.getElementById("ProductValueHidden").value = "";
          document.getElementById("IdProductHidden").value = "";
        }catch(err) {

        }
        setMsgTransacion({
          type: "success",
          text: data.msg,
        });
        setValues({
          name: values.name,
          cpf: values.cpf,
          email: values.email,
          phone: values.phone,
          zipcode: values.zipcode,
          number: values.number,
          complement: values.complement,
          address: values.address,
          neighborhood: values.neighborhood,
          city: values.city,
          uf: values.uf,
          card_number: "",
          card_name_printed: "",
          installments: "",
          card_expire_month: "",
          card_expire_year: "",
          cvv: "",
          amount: 1,
        });
        setLoading(false);
      }, 1000);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setMsgTransacion({
          type: "danger",
          text: err.response.data.msg,
        });
      } else if (err.response && err.response.status === 500) {
        setMsgTransacion({
          type: "danger",
          text: "Ocorreu um erro em nossos servidores, tente novamente mais tarde.",
        });
      } else {
        setMsgTransacion({
          type: "danger",
          text: "Ocorreu um erro ao enviar, tente novamente.",
        });
      }
      setLoading(false);
    }
  }

  return (
    <div id="container_checkout">
      {Object.keys(productDetail).length > 0 && (
        <ModalProduct product={productDetail} handleClose={() => setProductDetail({})}/>
      )}
      
      <div id="resume" style={{ backgroundColor: colors.primary }}>
        <img src={companyLogo} alt="Tupi.io" />
        {Object.keys(data).length > 0 && (
          <div id="product_section">
            <div
              className="d-flex align-items-center justify-content-between p-2 mb-1"
              style={{ width: "100%" }}
            >
              <FaCartPlus style={{ fontSize: "30px" }} />
              <h6 className="text-right mb-0">Seu carrinho:</h6>
            </div>
            <table id="list_added" className="mb-3">
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Valor</th>
                  <th>Quantidade</th>
                  <th className="text-right">Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          backgroundImage: `url(${
                            process.env.NODE_ENV === "development"
                              ? "http://localhost:3333"
                              : "https://checkout.api.tupi.io"
                          }/files/${data.image})`,
                        }}
                      ></div>
                      <strong>{data.name}</strong>
                      <button type="button" className="text-primary" style={{ border: 'none', background: 'none' }} onClick={() => setProductDetail(data)}>
                        Mais Detalhes do Produto <FaInfoCircle />
                      </button>
                    </div>
                  </td>
                  <td>R$ {normalizeCurrency(data.value)}</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <button
                        type="button"
                        style={{
                          background: "none",
                          border: "none",
                          color: colors.secondary,
                          opacity: values.amount === 1 ? "0.5" : "1",
                        }}
                        onClick={() =>
                          setValues({
                            ...values,
                            amount: values.amount - 1,
                          })
                        }
                        disabled={values.amount === 1}
                      >
                        <FaMinusCircle />
                      </button>
                      <span className="mb-0">{values.amount}</span>
                      <button
                        type="button"
                        style={{
                          background: "none",
                          border: "none",
                          color: colors.secondary,
                        }}
                        onClick={() =>
                          setValues({
                            ...values,
                            amount: values.amount + 1,
                          })
                        }
                      >
                        <FaPlusCircle />
                      </button>
                    </div>
                  </td>
                  <td className="text-right">
                    R$ {normalizeCurrency(data.value * values.amount)}
                  </td>
                  <td></td>
                </tr>
                {productsAdded.map((p) => (
                  <tr key={p.product.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div
                          style={{
                            backgroundImage: `url(${
                              process.env.NODE_ENV === "development"
                                ? "http://localhost:3333"
                                : "https://checkout.api.tupi.io"
                            }/files/${p.product.image})`,
                          }}
                        ></div>
                        <strong>{p.product.name}</strong>
                        <button type="button" className="text-primary" style={{ border: 'none', background: 'none' }} onClick={() => setProductDetail(p.product)}>
                          Ver mais <FaInfoCircle />
                        </button>
                      </div>
                    </td>
                    <td>R$ {normalizeCurrency(p.product.value)}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <button
                          type="button"
                          style={{
                            background: "none",
                            border: "none",
                            color: colors.secondary,
                            opacity: p.quantity === 1 ? "0.5" : "1",
                          }}
                          onClick={() =>
                            setProductsAdded(
                              productsAdded.map((i) => {
                                return {
                                  ...i,
                                  quantity:
                                    i.product.id === p.product.id
                                      ? i.quantity - 1
                                      : i.quantity,
                                };
                              })
                            )
                          }
                          disabled={p.quantity === 1}
                        >
                          <FaMinusCircle />
                        </button>
                        <span className="mb-0">{p.quantity}</span>
                        <button
                          type="button"
                          style={{
                            background: "none",
                            border: "none",
                            color: colors.secondary,
                          }}
                          onClick={() =>
                            setProductsAdded(
                              productsAdded.map((i) => {
                                return {
                                  ...i,
                                  quantity:
                                    i.product.id === p.product.id
                                      ? i.quantity + 1
                                      : i.quantity,
                                };
                              })
                            )
                          }
                        >
                          <FaPlusCircle />
                        </button>
                      </div>
                    </td>
                    <td className="text-right">
                      R$ {normalizeCurrency(p.product.value * p.quantity)}
                    </td>
                    <td>
                      <button
                        type="button"
                        style={{
                          background: "none",
                          border: "none",
                        }}
                        onClick={() =>
                          setProductsAdded(
                            productsAdded.filter(
                              (i) => i.product.id !== p.product.id
                            )
                          )
                        }
                        className="p-0 m-0 text-danger"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={2}>
                    <strong>TOTAL</strong>
                  </td>
                  <td colSpan={2} className="text-right">
                    <strong>R$ {normalizeCurrency(total)}</strong>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            {target !== 'single' && (
              <>
              <div className="p-2 mt-3 mb-1">
              <h6 className="mb-0">Aproveite Outros Produtos</h6>
            </div>
            
            {others.filter(
              (o) => !productsAdded.find((s) => s.product.id === o.id)
            ).length === 0 && (
              <div id="no_others">Nenhum produto encontrado</div>
            )}
            <div id="list_others">
              {others
                .filter(
                  (o) => !productsAdded.find((s) => s.product.id === o.id)
                )
                .map((o) => (
                  <div key={o.id}>
                    <div
                      className="img_other"
                      style={{
                        backgroundImage: `url(${
                          process.env.NODE_ENV === "development"
                            ? "http://localhost:3333"
                            : "https://checkout.api.tupi.io"
                        }/files/${o.image})`,
                      }}
                    ></div>
                    <h5>{o.name}</h5>
                    <div className="button_group">
                      <button
                        type="button"
                        style={{
                          background: colors.secondary,
                          borderColor: colors.primary,
                        }}
                        onClick={() =>
                          setProductsAdded([
                            ...productsAdded,
                            { product: o, quantity: 1 },
                          ])
                        }
                      >
                        <FaPlusCircle />
                        Adicionar
                      </button>
                      <button type="button" onClick={() => setProductDetail(o)}>
                        <FaInfoCircle />
                      </button>
                    </div>
                  </div>
                ))}
            </div>
              </>
            )}
            <div className="mt-4 pl-3 pr-3 pt-2 pb-2">
              <small>
                Produtos por:
                <br />
                <b>{data.credencial.filial}</b>
                <br />
                <b>{normalizeCnpj(data.credencial.cnpj)}</b>
              </small>
            </div>
          </div>
        )}
      </div>
          {loading ? (
            <div id="loading__screen">
              <h5>Buscando Informações...</h5>
              <Spinner
                animation="border"
              />
            </div>
          ) : (
            <>
              {errorProduct !== "" && (
                <div id="error_product">
                  <AiFillApi style={{ fontSize: "100px" }} />
                  <p>{errorProduct}</p>
                </div>
              )}
              {Object.keys(data).length > 0 && (
                <>
                  {msgTransaction.text !== "" &&
                  msgTransaction.type === "success" ? (
                    <div className="d-flex justify-content-center align-items-center flex-column" style={{ width: '100%' }}>
                      <Lottie
                        options={defaultOptions}
                        style={{ width: "250px", height: "250px" }}
                      />
                        <h4
                          className="text-center"
                          style={{ color: colors.secondary }}
                        >
                          {msgTransaction.text}
                        </h4>
                        <p className="text-center">
                          Em breve os responsáveis entrarão em contato
                        </p>
                      </div>
                  ) : (
                    <div style={{ width: "100%" }} id="checkout_form">
                        <Form onSubmit={handleSubmit}>
                          {msgTransaction.text !== "" &&
                            msgTransaction.type === "danger" && (
                              <div>
                                <Alert variant="danger">
                                  {msgTransaction.text}
                                </Alert>
                              </div>
                            )}
                          <fieldset>
                            <legend className="mb-0">
                              <FaUser style={{ fontSize: "12px" }} /> Seus dados
                            </legend>
                            <Form.Text className="mb-2">
                              Entraremos em contato através desses dados.
                              Preencha com atenção.
                            </Form.Text>
                            <Form.Group className="mb-0">
                              <Form.Control
                                type="text"
                                required
                                placeholder="Nome"
                                value={values.name}
                                onChange={(e) =>
                                  setValues({ ...values, name: e.target.value })
                                }
                                size="sm"
                              />
                              {errors.name !== "" && (
                                <Form.Text className="text-danger">
                                  {errors.name}
                                </Form.Text>
                              )}
                            </Form.Group>
                            <Form.Group className="mb-2 mt-2">
                              <Form.Control
                                type="text"
                                required
                                placeholder="CPF"
                                value={values.cpf}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    cpf: normalizeCpf(e.target.value),
                                  })
                                }
                                size="sm"
                              />
                              {errors.cpf !== "" && (
                                <Form.Text className="text-danger">
                                  {errors.cpf}
                                </Form.Text>
                              )}
                            </Form.Group>
                            <div className="grid_input">
                              <Form.Group className="mb-0">
                                <Form.Control
                                  type="email"
                                  placeholder="Email"
                                  value={values.email}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      email: e.target.value,
                                    })
                                  }
                                  size="sm"
                                  required
                                />
                                {errors.email !== "" && (
                                  <Form.Text className="text-danger">
                                    {errors.email}
                                  </Form.Text>
                                )}
                              </Form.Group>
                              <Form.Group className="mb-0">
                                <Form.Control
                                  type="text"
                                  placeholder="Telefone"
                                  value={values.phone}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      phone: normalizePhone(e.target.value),
                                    })
                                  }
                                  size="sm"
                                  required
                                />
                                {errors.phone !== "" && (
                                  <Form.Text className="text-danger">
                                    {errors.phone}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </div>
                          </fieldset>
                          <fieldset className="mt-2">
                                  <legend className="mb-0">
                                    <MdPersonPinCircle
                                      style={{ fontSize: "18px" }}
                                    />{" "}
                                    Código do Vendedor
                                  </legend>
                                  <div className="d-flex align-items-start mt-2">
                                    <Form.Group className="mb-0">
                                      <Form.Control
                                        placeholder="Digite o Código"
                                        value={sellerDigit}
                                        onChange={(e) => {
                                          setSellerDigit(
                                            e.target.value.toUpperCase()
                                          );
                                          setErrorSeller("");
                                        }}
                                        disabled={loading || sellerApplied.id}
                                        style={{
                                          borderRadius: "4px 0px 0px 4px",
                                        }}
                                      />
                                      <Form.Text className="text-danger">
                                        {errorSeller}
                                      </Form.Text>
                                    </Form.Group>
                                    {!sellerApplied.id && (
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        style={{
                                          borderRadius: "0px 4px 4px 0px",
                                        }}
                                        disabled={sellerDigit === "" || loading}
                                        onClick={handleApplySeller}
                                      >
                                        Aplicar
                                      </button>
                                    )}
                                    {sellerApplied.id && (
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        style={{
                                          borderRadius: "0px 4px 4px 0px",
                                        }}
                                        disabled={loading}
                                        onClick={() => setSellerApplied({})}
                                      >
                                        Remover
                                      </button>
                                    )}
                                  </div>
                                </fieldset>
                              
                          <fieldset className="mt-2">
                            <legend>
                              <FaCreditCard style={{ fontSize: "12px" }} />{" "}
                              Dados de Pagamento
                            </legend>
                            <Form.Group className="mb-0">
                              <Form.Control
                                type="text"
                                placeholder="Número do Cartão"
                                value={values.card_number}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    card_number: normalizeCardNumber(
                                      e.target.value
                                    ),
                                  })
                                }
                                size="sm"
                                required
                              />
                              {errors.card_number !== "" && (
                                <Form.Text className="text-danger">
                                  {errors.card_number}
                                </Form.Text>
                              )}
                            </Form.Group>
                            <Form.Group className="mb-0 mt-2">
                              <Form.Control
                                type="text"
                                required
                                placeholder="Nome no Cartão"
                                value={values.card_name_printed}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    card_name_printed:
                                      e.target.value.toUpperCase(),
                                  })
                                }
                                size="sm"
                              />
                              {errors.card_name_printed !== "" && (
                                <Form.Text className="text-danger">
                                  {errors.card_name_printed}
                                </Form.Text>
                              )}
                            </Form.Group>
                            <Form.Group className="mb-0 mt-2">
                              <Form.Control
                                as="select"
                                required
                                value={values.installments}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    installments: e.target.value,
                                  })
                                }
                                size="sm"
                              >
                                <option value="">Parcelamento</option>
                                {renderInstallments}
                              </Form.Control>
                              {errors.installments !== "" && (
                                <Form.Text className="text-danger">
                                  {errors.installments}
                                </Form.Text>
                              )}
                            </Form.Group>
                            <div className="grid_input_3 mt-2">
                              <Form.Group className="mb-0">
                                <Form.Control
                                  as="select"
                                  required
                                  value={values.card_expire_month}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      card_expire_month: e.target.value,
                                    })
                                  }
                                  size="sm"
                                >
                                  <option value="">Mês de Validade</option>
                                  <option value="01">01</option>
                                  <option value="02">02</option>
                                  <option value="03">03</option>
                                  <option value="04">04</option>
                                  <option value="05">05</option>
                                  <option value="06">06</option>
                                  <option value="07">07</option>
                                  <option value="08">08</option>
                                  <option value="09">09</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                </Form.Control>
                                {errors.card_expire_month !== "" && (
                                  <Form.Text className="text-danger">
                                    {errors.card_expire_month}
                                  </Form.Text>
                                )}
                              </Form.Group>
                              <Form.Group className="mb-0">
                                <Form.Control
                                  as="select"
                                  required
                                  value={values.card_expire_year}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      card_expire_year: e.target.value,
                                    })
                                  }
                                  size="sm"
                                >
                                  <option value="">Ano de Validade</option>
                                  {renderYears}
                                </Form.Control>
                                {errors.card_expire_year !== "" && (
                                  <Form.Text className="text-danger">
                                    {errors.card_expire_year}
                                  </Form.Text>
                                )}
                              </Form.Group>
                              <Form.Group className="mb-0">
                                <Form.Control
                                  type="text"
                                  placeholder="CVV"
                                  value={values.card_cvv}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      card_cvv: normalizeCvv(e.target.value),
                                    })
                                  }
                                  size="sm"
                                />
                                {errors.card_cvv !== "" && (
                                  <Form.Text className="text-danger">
                                    {errors.card_cvv}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </div>
                          </fieldset>
                          <button
                            type="submit"
                            className="btn btn-success btn-block btn-upper mt-4"
                            style={{
                              background: colors.secondary,
                              borderColor: colors.primary,
                            }}
                          >
                            Realizar Pagamento
                          </button>
                        </Form>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <ReCAPTCHA
            sitekey="6Lf8NtUaAAAAAEJyuqWiJAo9K4-y_2KqBfP6A_VM"
            size="invisible"
            ref={captcha}
          />
    </div>
  );
}

export default Checkout;
